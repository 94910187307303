<template>
  <div>
    <form autocomplete="off" @submit.prevent="handleSubmit">
      <input
        autocomplete="off"
        name="hidden"
        type="text"
        style="display: none"
      />
      <div class="row" :class="{ 'has-error': errors.has('id') }">
        <div class="form-group col-sm-4">
          <label>{{ $t("discount-codes") }}</label>
          <v-select
            name="id"
            v-model="dates.id"
            v-validate="'required'"
            :label="labelSelect"
            :filter-by="this.filterSelect"
            @search="getDiscountCodeList"
            :options="discountCodeListSelect"
            :reduce="discountCode => discountCode.id"
            :placeholder="$t('start-writing-to-search')"
          >
            <template #option="discountCodeListSelect">
              <div class="product-label">
                {{ discountCodeListSelect.name }}
              </div>
              <br />
              <cite>Id: {{ discountCodeListSelect.id }}</cite>
              <hr />
            </template>
          </v-select>
          <small v-show="errors.has('id')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
        <div class="col-8">
          <div class="row">
            <div
              class="form-group col-sm-4"
              :class="{ 'has-error': errors.has('dateStart') }"
            >
              <label>
                {{ $t("start_date") }}
              </label>
              <date-picker
                class="NeoDataPicker"
                v-model="dates.dateStart"
                :config="optionsDate"
                name="dateStart"
                autocomplete="off"
                v-validate="'required'"
              />
              <small v-show="errors.has('dateStart')" class="text-danger">
                {{ $t("field_required") }}
              </small>
            </div>
            <div
              class="form-group col-sm-4"
              :class="{ 'has-error': errors.has('dateEnd') }"
            >
              <label>
                {{ $t("end_date") }}
              </label>
              <date-picker
                class="NeoDataPicker"
                v-model="dates.dateEnd"
                :config="optionsDate"
                name="dateEnd"
                autocomplete="off"
                v-validate="'required'"
              />
              <small v-show="errors.has('dateEnd')" class="text-danger">
                {{ $t("field_required") }}
              </small>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="btn btn-primary ml-1" @click="setTime(30)">
                {{ $t("last_month") }}
              </div>
              <div class="btn btn-primary ml-1" @click="setTime(90)">
                {{ $t("last_3_months") }}
              </div>
              <div class="btn btn-primary ml-1" @click="setTime(180)">
                {{ $t("last_6_months") }}
              </div>
              <div class="btn btn-primary ml-1" @click="setTime(365)">
                {{ $t("last_12_months") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="form-group row">
        <div class="col-sm-4 col-sm-offset-2">
          <button class="btn btn-primary btn-sm" type="submit">
            {{ $t("download_data") }}
          </button>
        </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-12 col-md-6 mt-2">
          <span v-if="statistics && statistics.discountCodeOrdersStatistics">
            <h2>{{ $t("using-code-orders") }}</h2>
            <div
              v-for="(index, i) in statistics.discountCodeOrdersStatistics"
              :key="i"
            >
              <ul>
                <li>
                  {{ $t("count-orders") }}:
                  <strong>{{ index["counted_orders"] }}</strong>
                </li>
                <li>
                  {{ $t("sum-net-orders") }}:
                  <strong
                    >{{ index["sum_total_amount_net"]
                    }}{{ index["currency"] }}</strong
                  >
                </li>
                <li>
                  {{ $t("sum-gross-orders") }}:
                  <strong
                    >{{ index["sum_total_amount_gross"]
                    }}{{ index["currency"] }}</strong
                  >
                </li>
                <li>
                  {{ $t("sum-net-orders-free-delivery") }}:
                  <strong
                    >{{ index["order_amount_free_delivery_net"]
                    }}{{ index["currency"] }}</strong
                  >
                </li>
                <li>
                  {{ $t("sum-gross-orders-free-delivery") }}:
                  <strong
                    >{{ index["order_amount_free_delivery_gross"]
                    }}{{ index["currency"] }}</strong
                  >
                </li>
              </ul>
            </div>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 mt-4">
          <span
            v-if="statistics && statistics.discountCodeOrderStatusStatistics"
          >
            <h2>
              {{ $t("count-orders") }} ({{ $t("breakdown-order-status") }})
            </h2>
            <p
              v-for="(status,
              i) in statistics.discountCodeOrderStatusStatistics"
              :key="i"
            >
              {{ status["status"] }}:
              <strong>{{ status["counted_orders"] }}</strong>
              (
              {{
                Math.round(
                  ((status["counted_orders"] * 100) / countedOrders) * 100
                ) / 100
              }}% )<br />
            </p>
          </span>
        </div>
        <div class="col-12 col-md-3 mt-3">
          <canvas id="discount-code-statistics-orders"></canvas>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 mt-5">
          <span
            v-if="statistics && statistics.discountCodeOrderStatusStatistics"
          >
            <h2>
              {{ $t("amount-orders") }} ({{ $t("breakdown-order-status") }})
            </h2>
            <p
              v-for="(status,
              i) in statistics.discountCodeOrderStatusStatistics"
              :key="i"
            >
              {{ status["status"] }}:
              <strong
                >{{ status["total_amount"] }}{{ status["currency"] }}</strong
              >
              (
              {{
                Math.round(
                  ((status["total_amount"] * 100) / countedAmount) * 100
                ) / 100
              }}% )<br />
            </p>
          </span>
        </div>
        <div class="col-12 col-md-3 mt-5">
          <canvas id="discount-code-statistics-amount"></canvas>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "DiscountCodes",
  data() {
    return {
      dates: {
        dateStart: null,
        dateEnd: null,
        id: null
      },
      statistics: {},
      discountCodeListSelect: [],
      countedOrders: 0,
      countedAmount: 0,
      labelSelect: "name",
      discountCodeStatistics: null,
      discountCodeOrdersStatistics: null,
      discountCodeOrderStatusStatistics: null,
      chartOrders: null,
      chartAmount: null,
      standardFormatDate: "YYYY-MM-DD"
    };
  },
  watch: {
    "dates.dateStart": function(newDateStart, oldDateStart) {
      if (newDateStart !== oldDateStart) {
        this.clearCharts();
        this.statistics = {};
      }
    },
    "dates.dateEnd": function(newDateEnd, oldDateEnd) {
      if (newDateEnd !== oldDateEnd) {
        this.clearCharts();
        this.statistics = {};
      }
    },
    "dates.id": function(newId, oldId) {
      if (newId !== oldId) {
        this.clearStats();
        this.clearCharts();
        this.statistics = {};
      }
    }
  },
  computed: {
    statisticUrl() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/statistics/discount-codes`;
    },
    urlDiscountCode() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/discount-codes`;
    }
  },
  created() {
    this.dates.dateEnd = this.$moment().format("YYYY-MM-DD");
    this.dates.dateStart = this.$moment(this.dates.dateEnd)
      .subtract(1, "months")
      .format("YYYY-MM-DD");
    this.$emit("loading", false);
  },
  methods: {
    setTime(time) {
      let months = Math.round(time / 30);
      this.dates.dateEnd = this.$moment().format(this.standardFormatDate);
      this.dates.dateStart = this.$moment(this.dates.dateEnd)
        .subtract(months, "months")
        .format(this.standardFormatDate);
    },
    filterSelect: (option, id, search) => {
      const temp = search.toLowerCase();
      if (!option.group) {
        return (
          option.name.toLowerCase().indexOf(temp) > -1 ||
          option.id.toString().indexOf(temp) > -1
        );
      }
      return (
        option.name.toLowerCase().indexOf(temp) > -1 ||
        option.group.toLowerCase().indexOf(temp) > -1
      );
    },
    async getDiscountCodeList(value) {
      if (value && value.length > 2) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$emit("loading", true);
          this.$http
            .get(this.urlDiscountCode, { params: { search: value } })
            .then(resp => {
              this.discountCodeListSelect = resp.data.data;
              this.$emit("loading", false);
            })
            .catch(err => {
              this.$toastr.error(err);
              this.$emit("loading", false);
            });
        }, 500);
      }
    },
    clearStats() {
      this.statistics !== null
        ? (this.statistics = {})
        : (this.statistics = null);
    },
    clearCharts() {
      this.chartOrders != null
        ? this.chartOrders.destroy()
        : (this.chartOrders = null);
      this.chartAmount != null
        ? this.chartAmount.destroy()
        : (this.chartAmount = null);
      this.$forceUpdate();
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("loading", true);
          this.$http
            .get(`${this.statisticUrl}`, {
              params: {
                dateStart: this.dates.dateStart,
                dateEnd: this.dates.dateEnd,
                discountCodeIds: this.dates.id
              }
            })
            .then(resp => {
              if (
                resp.data &&
                resp.data.discountCodeOrderStatusStatistics.length > 0
              ) {
                this.statistics = resp.data;

                if (this.statistics.discountCodeOrderStatusStatistics) {
                  Object.keys(
                    this.statistics.discountCodeOrderStatusStatistics
                  ).map(key => {
                    this.countedOrders += this.statistics.discountCodeOrderStatusStatistics[
                      key
                    ].counted_orders;
                    return this.statistics.discountCodeOrderStatusStatistics[
                      key
                    ].counted_orders;
                  });
                  this.chartOrders = this.createChart(
                    "discount-code-statistics-orders",
                    this.statistics.discountCodeOrderStatusStatistics,
                    "counted_orders"
                  );
                }
                if (this.statistics.discountCodeOrderStatusStatistics) {
                  Object.keys(
                    this.statistics.discountCodeOrderStatusStatistics
                  ).map(key => {
                    this.countedAmount += this.statistics.discountCodeOrderStatusStatistics[
                      key
                    ].total_amount;
                    return this.statistics.discountCodeOrderStatusStatistics[
                      key
                    ].total_amount;
                  });
                  this.chartAmount = this.createChart(
                    "discount-code-statistics-amount",
                    this.statistics.discountCodeOrderStatusStatistics,
                    "total_amount"
                  );
                }
              } else {
                this.$toastr.error(this.$t("no-orders-selected-criterion"));
              }

              this.$emit("loading", false);
            })
            .catch(err => {
              this.$toastr.error(err);
              this.$emit("loading", false);
            });
        }
      });
    },
    createChart(chartId, data, key) {
      const labels = Object.keys(data).map(value => data[value].status);
      const datasets = Object.keys(data).map(value => data[value][key]);
      const myChart = new Chart(document.getElementById(chartId), {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Darmowa dostawa statystyka",
              data: datasets,
              backgroundColor: [
                "rgb(150, 153, 203)",
                "rgb(254, 200, 216)",
                "rgb(255, 223, 211)",
                "rgb(182, 211, 235)",
                "rgb(210, 145, 188)",
                "rgb(224, 187, 228)",
                "rgb(149, 125, 173)"
              ]
            }
          ]
        }
      });
      return myChart;
    }
  }
};
</script>
style>

<style>
.product-label {
  display: flex;
  justify-content: space-between;
  align-dates: center;
}
.v-select input::placeholder {
  color: rgb(167, 167, 167) !important;
}
</style>
